<script>
import { mapActions, mapGetters } from 'vuex'
import { ORGANIZATION_SUPPLIER_TYPE_CODE } from '@/const'
import { TYPES as SUPPLIER_CONTACT_TYPES } from './store'
import { TYPES as CNT_ORG_TYPES } from '../../../organization-contact/store'
import { ROUTES as SUPPLIER_ORG_ROUTES } from '../../../organization/organization/supplier-organization/router'
import { ROUTES as SUPPLIER_CONTACT_ROUTES } from './router'

import ContactCreateMixin from '../../../components/contact/mixins/ContactCreateMixin'

export default {
  name: 'SupplierContactCreate',
  mixins: [ContactCreateMixin],
  data () {
    return {
      orgId: this.$route.params.id,
      id: this.$route.params.cntId,
      orgType: ORGANIZATION_SUPPLIER_TYPE_CODE,
      routeNameDetail: SUPPLIER_CONTACT_ROUTES.SUPPLIER_CONTACT_DETAIL,
      SUPPLIER_ORG_ROUTES,
      SUPPLIER_CONTACT_ROUTES,
      ORGANIZATION_SUPPLIER_TYPE_CODE,
      showDuplicatedContactModal: false
    }
  },
  computed: {
    ...mapGetters({
      ...SUPPLIER_CONTACT_TYPES.GENERIC.organization.suppliercontact.DETAIL.GETTERS
    })
  },
  methods: {
    ...mapActions({
      ...SUPPLIER_CONTACT_TYPES.GENERIC.organization.suppliercontact.DETAIL.ACTIONS,
      getContactList: SUPPLIER_CONTACT_TYPES.GENERIC.organization.suppliercontact.LIST.ACTIONS.getList,
      createOrganizationContact: CNT_ORG_TYPES.GENERIC.organization.organizationcontact.DETAIL.ACTIONS.create
    }),
    onCancel () {
      this.$emit('close-form')
    }
  }
}
</script>
