<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as SUPPLIER_CONTACT_TYPES } from './store'
import { ROUTES as SUPPLIER_CONTACT_ROUTES } from './router'
import { ROUTES as SUPPLIER_ORG_ROUTES } from '../../../organization/organization/supplier-organization/router'

import SupplierContactTable from './SupplierContactTable'
import SupplierContactCreate from './SupplierContactCreate'
import SupplierContactDetail from './SupplierContactDetail'
import SupplierCommonFIlters from './SupplierCommonFIlters'
import ContactListMixin from '../../../components/contact/mixins/ContactListMixin'

export default {
  name: 'SupplierContactList',
  props: ['orgId'],
  mixins: [ContactListMixin],
  components: {
    'component-table': SupplierContactTable,
    'common-filters': SupplierCommonFIlters,
    'component-edit': SupplierContactCreate,
    'component-detail': SupplierContactDetail
  },
  data () {
    return {
      SUPPLIER_ORG_ROUTES,
      SUPPLIER_CONTACT_ROUTES,
      title: this.$t('Contacts'),
      rolePerm: ['organization_suppliercontact_list'],
      actionEnablePermission: ['organization_suppliercontact_enable'],
      actionDisablePermission: ['organization_suppliercontact_disable']
    }
  },
  computed: {
    ...mapGetters({
      ...SUPPLIER_CONTACT_TYPES.GENERIC.organization.suppliercontact.LIST.GETTERS
    }),
    showList () {
      return (
        this.$route.name === this.SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_DETAIL ||
          this.$route.name === this.SUPPLIER_CONTACT_ROUTES.SUPPLIER_CONTACT_LIST
      )
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: SUPPLIER_CONTACT_TYPES.GENERIC.organization.suppliercontact.LIST.MUTATIONS.setSelectedList,
      setSelectAll: SUPPLIER_CONTACT_TYPES.GENERIC.organization.suppliercontact.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...SUPPLIER_CONTACT_TYPES.GENERIC.organization.suppliercontact.LIST.ACTIONS
    }),
    openDetailForm (cntId) {
      this.$emit('show-contact-detail', true)
      if (this.orgId) {
        this.$router.push({
          name: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_CONTACT_DETAIL, params: { orgId: this.orgId, cntId: cntId }
        })
      } else {
        this.$router.push({
          name: SUPPLIER_CONTACT_ROUTES.SUPPLIER_CONTACT_DETAIL, params: { cntId: cntId }
        })
      }
    }
  }
}
</script>
