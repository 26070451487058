<script>
import { mapGetters, mapActions } from 'vuex'
import { TYPES as SUPPLIER_CONTACT_TYPES } from './store'
import ContactCommonFiltersMixin from '../../../components/contact/mixins/ContactCommonFiltersMixin'

export default {
  name: 'SupplierContactCommonFilters',
  mixins: [ContactCommonFiltersMixin],
  computed: {
    ...mapGetters({
      filters: SUPPLIER_CONTACT_TYPES.GENERIC.organization.suppliercontact.LIST.GETTERS.filters
    })
  },
  methods: {
    ...mapActions({
      ...SUPPLIER_CONTACT_TYPES.GENERIC.organization.suppliercontact.LIST.ACTIONS
    })
  }
}
</script>
