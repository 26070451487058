<template>
  <div class="sidebar-filters">
    <b-button class="text-uppercase" ref="escape" variant="reset-all" @click.prevent="resetFilters()">
      <i class="fas fa-eraser" /> {{ 'Reset all filters' | translate }}
    </b-button>
    <status-list-filter
      @edit="editComponentFilters($event, 'filter__status__in')"
      @clean="cleanComponentFilters(['filter__status__in'])"
    />
    <organization-list-filter
      @edit="editComponentFilters($event, 'filter__organizations__in')"
      @clean="cleanComponentFilters(['filter__organizations__in'])"
    />
    <role-list-filter
      @edit="editComponentFilters($event, 'filter__organization_contacts__roles__in')"
      @clean="cleanComponentFilters(['filter__organization_contacts__roles__in'])"
    />
    <security-role-list-filter
      @edit="editComponentFilters($event, 'filter__organization_contacts__security_roles__in')"
      @clean="cleanComponentFilters(['filter__organization_contacts__security_roles__in'])"
    />
  </div>
</template>

<script>
import CommonFiltersMixin from '@/pages/common-filters/CommonFiltersMixin'

export default {
  name: 'ContactCommonFiltersMixin',
  mixins: [CommonFiltersMixin]
}
</script>
